<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{path: '/questionset',query:{activeNames:activeNames}}">错题集</el-breadcrumb-item>
      <el-breadcrumb-item>{{classesinfo.Year}}{{classesinfo.Season}}{{classesinfo.SubSeason}}{{classesinfo.Subject}}{{classesinfo.ClassType}}{{classesinfo.GradeStart}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-collapse accordion
                 v-model="activeNames"
                 @change="handleChange"
                 class="questiondetail">
      <el-collapse-item v-for="(item,index) in mistakesdetails"
                        :key="index"
                        :name="index+1">
        <template slot="title">
          <div class="questiontit">
            <p> <span class="bold numcount">第{{item.num}}题：</span> <span v-html="item.title"></span></p>
            <span class="color1"
                  v-if="activeNames==''">查看解析</span>
            <span class="color1"
                  v-else-if='activeNames==(index+1)'>收起</span>
            <span class="color1"
                  v-else>查看解析</span>
          </div>
        </template>
        <div>
          <p><span class="lefttype">答案：</span><span v-html="item.answer"></span></p>
          <p class="analysis"><span class="lefttype">解析：</span>  <span v-html="item.analysis"></span></p>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { get_mistakes_details } from '@/api/classesinfo'
export default {
  name: 'questionset_detail',
  data () {
    return {
      activeNames: 1,
      prames:{},
      classesinfo:{},
      mistakesdetails:{},
      activeNames:''
    };
  },
  created () {
    var query = sessionStorage.getItem('stu_question_query')? JSON.parse(sessionStorage.getItem('stu_question_query')):{}
    this.prames.userid=query.userid||null;
    this.prames.DBType=query.DBType||null;
    this.prames.OprId=query.OprId||null;
    this.prames.LessonNo=query.LessonNo||null;
    this.activeNames=query.activeNames||null;
    this.getInit(this.prames)
    window.history.pushState(null, null, document.URL);
    window.addEventListener("popstate", this.goback, false);
  },
  destroyed () {
    window.removeEventListener("popstate", this.goback, false);
  },
  methods: {
    goback(){
      this.$router.push({
         path: '/questionset',
         query:{
           activeNames:this.activeNames
         }
      })
    },
      async getInit (prames) {
      let res = await get_mistakes_details(prames)
      if (res.flag == '1') {
        this.classesinfo = res.datainfo.classesinfo;
        this.mistakesdetails =  res.datainfo.mistakesdetails
      } else if (res.flag == '-1') {
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });   
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          showClose: false,
          type: 'error',
        });
      }
    },
    handleChange (val) {
      this.activeNames = val
    },
  },

}
</script>
<style lang="scss" scoped>
.questiondetail {
  margin-top: 30px;
  border: none !important;
  .el-collapse-item {
    margin-bottom: 20px;
    border: 1px solid #e9e9ec;
    border-radius: 10px;
    overflow: hidden;
    background: #f1f2fa;
  }
  .questiontit{
    width:100%;
    display: flex;
    justify-content: space-between;
    p{
      width:85%;
      display: flex;
    }
    .numcount{
      min-width: 80px;
    }
  }
  .analysis{
    margin-top:10px;
    display: flex;
    align-items: flex-start;
  }
  .lefttype{
    min-width: 55px;
  }
}
</style>
<style lang="scss">
.questiondetail {
  img{
    vertical-align: middle;
  }
  .el-collapse-item__header {
    border-bottom: 1px solid #e9e9ec !important;
    background: none !important;
    padding: 10px 25px !important;
    font-size: 18px !important;
    height: auto!important;
    line-height: 32px!important;
    align-items: baseline;
  }
  .el-collapse-item__wrap {
    border-bottom: none !important;
    background: none !important;
    padding: 15px 25px 0 !important;
    font-size: 18px !important;
  }
  .el-collapse-item__content {
    font-size: 18px !important;
  }
  .el-collapse-item__arrow {
    color: #6f72ff;
  }
}
</style>